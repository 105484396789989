import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./style.scss";
import { dsnCN } from "../../hooks/helper";
import { Subscribe } from "../../services/apiservice";
import { useToasts } from "react-toast-notifications";
function ContactForm({ className }) {
  const form = useRef();
  const [loading, setLoading] = useState();
  const [result, setResult] = useState();
  const [name, setName] = useState();

  const [email, setEmail] = useState();
  const [description, setDescription] = useState();
  const { addToast } = useToasts();

  const Subscription = async (e) => {
    e.preventDefault();

    let result;

    let body = {
      apiKey: process.env.REACT_APP_MAILCHIMP_API_KEY,
      listId: process.env.REACT_APP_LIST_ID,
      data: {
        merge_fields: {
          FNAME: name,
          WHATSUP: description,
        },

        email_address: email,
      },
    };
    // console.log("username", username);
    console.log(body, "Body");

    try {
      result = await Subscribe(body);
      console.log("result out", result);
      if (result.success) {
        console.log("result", result);
        console.log("formapikey", result.apiKey);
        console.log("formlistkey", result.listId);
        addToast("Successfully submitted....", {
          appearance: "success",
          autoDismiss: true,
        });
      }
      setResult(result);
    } catch (error) {
      return addToast("Already Exists this Email...", {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setResult(false);
    setLoading(true);
    emailjs
      .sendForm(
        "service_mxt8ue9",
        "template_1f1243o",
        form.current,
        "bvnMqTU1Ir-7kgLNL"
      )
      .then(
        (result) => {
          setLoading(false);
          setResult(true);
          form.current.reset();
        },
        (error) => {
          setLoading(false);
        }
      );
  };

  setTimeout(() => setResult(false), 5000);

  return (
    <div className={dsnCN("form-box", className)}>
      <h3 className="mb-30">GET EARLY ACCESS TO OUR WHITELIST</h3>
      <form ref={form} onSubmit={Subscription}>
        <div className="input__wrap controls">
          <div className="form-group">
            <label>What's your name?</label>
            <input
              id="form_name"
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Type your name"
              required="required"
            />
          </div>

          <div className="form-group">
            <label>What's your email address?</label>
            <input
              id="form_email"
              type="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Type your Email Address"
              required="required"
            />
          </div>

          <div className="form-group">
            <label>What's up?</label>
            <textarea
              id="form_message"
              className="form-control"
              name="message"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Tell us about you and the world"
              required="required"
            />
          </div>

          <div className="image-zoom">
            <button type="submit">Send Message</button>
          </div>
          {loading && (
            <div className="loading-message mt-20">Message Send ...</div>
          )}
          {result && (
            <p className="success-message mt-20">
              Your Message has been successfully sent. I will contact you soon.
            </p>
          )}
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
