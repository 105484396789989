import React from "react";
import Navbar, { Nav } from "../../nav/Navbar";
import Brand from "../../brand/Brand";
import MenuContent from "./MenuContent";
import { Web3Button, Web3NetworkSwitch } from "@web3modal/react";

const menuContent = [
  {
    name: "HOME",
    to: "#topHeroImage",
  },
  {
    name: "ABOUT",
    to: "#whoWeAre",
  },
  {
    name: "BENEFITS",
    to: "/",
  },
  { name: "MEMBERSHIP", to: "/about" },
  { name: "WHITELIST", to: "/contact" },
];

const DroowMenu = ({ hamburger }) => {
  let $key = 0;
  const getSubMenu = (items) => {
    $key++;
    if (items.dropdownMenu) {
      return (
        <Nav.Dropdown name={items.name} key={$key}>
          {items.dropdownMenu.map((item) => getSubMenu(item))}
        </Nav.Dropdown>
      );
    }
    return (
      <Nav.Link to={items.to} key={$key}>
        {items.name}
      </Nav.Link>
    );
  };

  return (
    <Navbar hamburger={hamburger}>
      <Navbar.Brand to={"/"}>
        <Brand className='brand'  />
      </Navbar.Brand>
      <>
        <Navbar.ConnectWallet to={"/"}>
          <Web3Button />
        </Navbar.ConnectWallet>
      </>
      <>
        <Navbar.SwitchNetwork to={"/"}>
          <Web3NetworkSwitch />
        </Navbar.SwitchNetwork>
      </>
      <Navbar.Collapse cover="Menu">
        <Nav>{menuContent.map((item) => getSubMenu(item))}</Nav>
        <MenuContent className="section-margin" />
      </Navbar.Collapse>
    </Navbar>
  );
};

DroowMenu.defaultProps = {
  hamburger: false,
};

export default DroowMenu;
