
export async function GetUSD() {
  let response;
  try {
    response = await fetch("https://metadata.cyberdogz.io/v1/usdprices", {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
    });
  } catch (e) {}

  if (response) {
    return response.json();
  }
}
export async function Subscribe(body) {
  let response;
  try {
    response = await fetch("https://resort.smartchain.in/v1/subscribe", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
  } catch (e) {}

  if (response.status === 200) {
    return await response.json();
  } else {
    let message = await response.json();
    throw new Error(message.error);
  }
}
