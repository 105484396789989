import React, { useLayoutEffect } from "react";
// Importing the Bootstrap CSS
import "swiper/css/pagination";

import "bootstrap/dist/css/bootstrap-grid.css";

import "./assets/sass/style.scss";

import Router from "./router/Router";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import dsnEffect, {
  fade,
  moveSection,
  textAnimation,
} from "./animation/DsnEffect";
import Layout from "./layout/Layout";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { mainnet } from "wagmi/chains";
import { Web3Modal } from "@web3modal/react";
import { ToastProvider } from "react-toast-notifications";

export const projectId = "2bc3903d7a5c074ba9a5fa9cfa7a8704";

// 2. Configure wagmi client
const chains = [mainnet];

const { provider } = configureChains(chains, [w3mProvider({ projectId })]);
const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, version: 1, chains }),
  provider,
});

export const ethereumClient = new EthereumClient(wagmiClient, chains);
function App() {
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    dsnEffect.registerEffect(moveSection, fade, textAnimation);

    gsap.config({
      nullTargetWarn: false,
    });
    gsap.defaults({
      ease: "none",
      duration: 1,
      overwrite: "auto",
    });
  }, []);

  return (
    <>
      <WagmiConfig client={wagmiClient}>
        <ToastProvider
          autoDismiss
          autoDismissTimeout={4000}
          placement="top-right"
        >
          <Layout className="v-dark background-main" tag="main">
            <Router />
          </Layout>
        </ToastProvider>
      </WagmiConfig>
      <Web3Modal
        projectId={projectId}
        ethereumClient={ethereumClient}
        themeVariables={{
          "--w3m-accent-color": "black",
          "--w3m-background-color":"black"
        }}
      />
    </>
  );
}

export default App;
