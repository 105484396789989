import React from "react";
import { Container } from "react-bootstrap";

import "./style.scss";
import NavLink from "../button/NavLink";
import { BsPrefixRefForwardingComponent } from "react-bootstrap/helpers";
import { NavLinkProps } from "react-bootstrap/NavLink";
import { dsnCN } from "../../hooks/helper";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import ButtonDefault from "../button/button-default/ButtonDefault";

interface NextPageProps
  extends BsPrefixRefForwardingComponent<"a", NavLinkProps> {
  text?: string;
}

function NextPage({ className, children, text, ...restProps }: NextPageProps) {
  return (
    <div className={dsnCN("next-page section-padding", className)}>
      <Container>
        <div className="c-wrap">
          <NavLink {...restProps}>
            <span style={{ whiteSpace: "nowrap" }} className="hiring">
              {children}
            </span>
            {/* <span className="career">{text}</span> */}
            <div style={{ position: "relative" }} className="button-box">
              {/* <a style={{ position: "absolute" }} target="_blank" href="https://google.com">
                                jasdfas
                            </a> */}
              <ButtonDefault
                onClick={() => window.open("https://discord.gg/omnieden")}
                text="JOIN DISCORD"
                target="_blank"
                icon={faAngleRight}
              />
            </div>
          </NavLink>
        </div>
      </Container>
    </div>
  );
}

export default NextPage;
